"use strict";

$(function () {
  // 모바일 대메뉴
  $('.mobile-header__nav .depth1 > a').on('click', function () {
    $(this).toggleClass('active');
  });
  $('.header__menu').on('click', function () {
    if ($(this).hasClass('open')) {
      $('.mobile-header').show();
    } else {
      $('.mobile-header').hide();
    }
  });
  $('.js-showPrice').on('click', function () {
    $(this).parents('.product__item').find('.product__priceInfo').show();
  });
  $('.product__priceInfo .close').on('click', function () {
    $(this).parents('.product__priceInfo').hide();
  });
  $('.modal__close').add('.js-modal-hide').on('click', function (e) {
    e.preventDefault();
    $(this).parents('.modal').hide();
  });
  $('.adBanner').slick({
    arrows: false,
    dots: true
  });
  $('.qna__item button').on('click', function () {
    $(this).toggleClass('active');
    $(this).siblings('.qna__answer').toggle();
  });
});

function showModal(target) {
  $('.modal').hide();
  $('#' + target).show();
}